<template>
  <esmp-link
    :to="`/tickets/create/${service.sourceSystem}/${service.id}`"
    class="favorite-service"
  >
    <div class="favorite-service__top">
      <esmp-icon
        class="favorite-service__info"
        v-if="service.description"
        name="info2"
        v-tooltip="{value: service.description}"
      />
      <div class="favorite-service__flag">
        <favorite-service-toggle-button
          is-icon-only
          :service-id="service.id"
          :source-system="service.sourceSystem"
        />
      </div>
    </div>
    <div class="favorite-service__middle">
      <div class="favorite-service__name">
        {{ service.name }}
      </div>
    </div>
    <div class="favorite-service__bottom">
      <esmp-tag
        v-if="service.type"
        color="green"
      >
        {{ service.type }}
      </esmp-tag>
    </div>
  </esmp-link>
</template>

<script>
import FavoriteServiceToggleButton from '@/components/favorite-service-toggle-button';

export default {
  name: 'FavoriteService',
  components: { FavoriteServiceToggleButton },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.favorite-service {
  position: relative;
  border-radius: 12px;
  // padding: 16px 16px 42px;
  padding: 16px;
  background: $color-white;
  transition: box-shadow $base-animation-time $base-animation-function;

  @include for-size(phone-portrait-down) {
    padding-right: 12px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  &__flag {
    margin-left: auto;
    margin-right: 0;
    color: $color-yellow;
  }

  &__name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $color-black;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 12px;
  }

  &__info {
    @include hover(true) {
      fill: $color-client-portal-logo;
    }
  }

  .esmp-tag {
    font-size: 11px;
    line-height: 16px;
  }

  &:hover {
    box-shadow: $base-shadow-xl;
  }
}
</style>
